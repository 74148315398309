.inputWrapper {
  position: relative;
}

.input {
  padding: 0.7rem;
  font-size: 1.2rem;
  border: 1px solid gray;
  transition: 0.3s;
  // border: 2px solid red;
  width: 100%;
  margin-top: 0.3rem;
  outline: none;
  
  &.withIcon {
    padding-left: 2.35rem;
  }

  &:hover {
      border: var(--border-primary);
  }

  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--color-ternary),
    0 1px 2px 0 rgba(255, 255, 255, 0.506);
  }


}

.icon {
  position: absolute;
  left: 0;
  bottom: 0;
  transform: translate(50%, -50%);
  z-index: 20;
}

