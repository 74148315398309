.input {
  padding: 0.7rem;
  font-size: 1.2rem;
  border: 1px solid gray;
  width: 100%;
  margin-top: 0.3rem;
  background-color: white;
  outline: none;
  cursor: pointer;
  &:hover {
    border: var(--border-primary);
  }

  &:focus {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px var(--color-ternary),
      0 1px 2px 0 rgba(255, 255, 255, 0.506);
  }
  &:disabled {
    border-color: rgb(187, 187, 187);
  }
}
.dropdownWrapper {
  position: relative;
}
.label {
  padding-left: 1rem;
}

.options {
  color: black;
  font-size: 1.5rem;
}

.buttonIcon {
  position: absolute;
  top: 100%;
  right: 0;
  transform: translate(-13px, -39px);
  font-size: 2rem;
  cursor: pointer;
}

.optionWrapper {
  display: none;
  background-color: rgb(209, 238, 255);
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  // height:115px;
  z-index: 101;
  border: 1px solid;
  max-height: 300px;
  overflow-y: auto;
}
.shown {
  display: block;
}
.dropdownButton {
  padding: 0.7rem;
  font-size: 1.2rem;
  // border: var( --border-color-primary) ;
  width: 100%;
  margin-top: 0.3rem;
  background-color: transparent;
  outline: none;
  border: none;
  &:hover {
    background-color: rgb(188, 209, 228);
  }
}

.disabled {
  .buttonIcon {
    color: gray;
  }
  opacity: 0.2;
}
