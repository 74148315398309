.buttonDiv {
  margin-top: 1rem;
  display: flex;
  padding: 0.5rem;
}
.dialogButton {
  width: 6rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  // margin-top: 2rem;
  background-color: var(--bg-body);
  color: var(--text-ternary);
  border: var(--border-color-primary);
  &:hover {
    background-color: var(--bg-ternary);
    color: var(--text-secondary);
  }
}

.dialogButton1 {
  width: 6rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
.bodyWpr {
  display: flex;
  padding: 0.5rem;
  border-right: none;
  &:last-child {
    border-right: 1px solid red;
  }
  .dataWpr {
    text-align: left;
    border-right: var(--border-ternary-dull);
    justify-content: center;
    flex-grow: 1;
    &:last-child {
      border-right: none;
    }
  }
}
.dialogBody {
  font-size: 1.2rem;
  font-weight: 800;
}
.savBtnWpr {
  margin-top: 1rem;
  display: flex;
}
.footerBtn {
  width: 6rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
}
.detailWrapper {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.5rem;
  border-bottom: var(--border-ternary-dull);
}

.label {
  font-weight: 400;
  font-size: 0.9rem;
}
.data {
  color: var(--text-ternary);
  font-weight: 600;
  font-size: 0.9rem;
}

.inputLabel {
  font-weight: 500;
  margin-bottom: 1rem;
}
.inputWrp {
  margin-top: 1rem;
}
.input {
  margin-top: 1rem;
}

.headerWrp {
  display: flex;
  justify-content: space-around;
  background-color: var(--bg-table-header);
  padding: 0.5rem;
  color: var(--text-ternary);
  font-weight: 700;
  font-size: 0.9rem;
}
.bodyWrp {
  padding: 0rem !important;
}
