@import "/src/styles/mixins/flexGrid.mixin.scss";

.body {
  display: flex;
  justify-content: center;
  color: var(--text-ternary);
  background-color: var(--bg-secondary);
}

.input {
  margin-top: 1.5rem;
}
.errorMessage {
  font-size: 1rem;
  padding-top: 0.5rem;
  color: red;
  position: absolute;
}
.wrapper {
  @include flex-centered($dir: column);
}
.logo {
  font-size: 3rem;
  border-bottom: 1px solid rgb(35, 106, 165);
  padding: 20px;
  padding-inline: 40px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.logoText {
  font-weight: 600;
}
.title {
  font-size: 2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  padding: 4rem 0;
  font-weight: 600;
}
.subtitle {
  font-size: 1.2rem;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.inputSection {
  position: relative;
  width: 100%;
  padding: 1.6rem 0;
}

.login {
  width: 100%;
  display: flex;
  padding-top: 1rem;
}

.screenBtn {
  display: inline-flex;
  align-items: center;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  border-radius: 0;
  margin: 0 0.25rem;
  border: var(--border-primary);
  cursor: pointer;
  color: var(--text-ternary);
  background: linear-gradient(#e5fbff, #c1dfff);
  font-weight: 600;
  &.active {
    background: linear-gradient(#77b9f1, #236aa6);
    color: white;
  }
}

@media screen and (max-width: 480px) {
  
  .title {
    font-size: 0.9rem;
  }
  .subtitle {
    font-size: 0.7rem;
  }

  .logo {
    height: 100px;
    width: 250px;
  }
}
