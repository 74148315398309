.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .loader{
    position: absolute;
  }
  
  