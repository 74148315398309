.layout01 {
  --max-display: 1920px;
  --default-gap: 0.5rem; // gap for grid column and rows.

  --radius-default: 1rem;
}

.bodyWrapper{

}
.contentWrapper{
  
}