.dateWrp {
  border: var(--border-primary);
  border-radius: 0.2rem;
  cursor: pointer;
  padding: 0.5rem;
  &:focus {
    outline: var(--border-primary); 
  }
}
.label {
  color: var(--text-ternary);
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.btnSet {
  // margin-left: 1rem;
  margin-top: 1.5rem;
}

.exportButton a {
  color: white;
}

.exportSet {
  margin-top: 1.5rem;
  margin-left: 0.5rem;
}
.headerWrapper {
  padding: 1rem;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.section1 {
  display: flex;
}

.section2 {
  display: flex;
  // margin-left: 0.5rem;
  align-items: center;
}

.dateWrap{
  margin-right: 1rem;
}

.filterIcon {
  // width: 4rem !important;
  transition: 0.3s;
  width: 100%;
  margin-right: 1rem;
  height: 2rem;

  &:hover {
    // border: 1px solid;
    background-color: white;
    color: var(--bg-ternary);
  }
}
