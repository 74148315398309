* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px;
}

button,
a {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  text-decoration: none;
}

.ACTIVE {
  color: #6bdb57;
}
.INACTIVE {
  color: #db5757;
}

.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-secondary);
  font-weight: 700;
  padding: 0.8rem;
  font-size: 1.5rem;
  /* margin-left: 1rem; */
  // padding: 1rem;
  padding-left: 20px;
  background-color: var(--bg-header);
}

.errorMsg {
  color: red;
  font-size: 1rem;
  font-weight: 500;
  padding: 0rem 1rem;
}

.text {
  font-size: 1rem;
}

.alignRight {
  justify-content: flex-end;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

::-webkit-scrollbar {
  width: 5px;
  height: 6px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar:hover {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgb(85, 177, 243);
  background-image: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: rgb(9, 129, 214);
  background-image: -webkit-linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0.2) 75%,
    transparent 75%,
    transparent
  );
}

