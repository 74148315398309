.filterWrapper {
  // padding: 2rem;
  display: flex;
  align-items: center;
  background-color: rgb(228, 245, 255);
  width: 100%;
  transition: 0.3s;
  padding: 0 2rem;
  width: 100%;
}

.filterFields {
  display: grid;
  align-items: center;
  justify-content: flex-start;
  transition: 0.3s;
  gap: 2rem;
  grid-template-columns: repeat(5, 1fr);
}
.filterShow {
  display: flex;
  /* height: 6rem; */
  padding: 1.5rem;
}

.filterButtonsDiv {
  display: flex;
  // margin-left: 2rem;

  .button {
    margin: 0 0.5rem;
    min-width: 5rem;
    padding: 0.55rem;
    // margin-top: 0.9rem;
    font-size: 0.9rem;
    background: var(--bg-ternary);
    border: none;
    color: white;
    border-radius: 0.2rem;
  }
}
.filterHide {
  height: 0rem;
  opacity: 0;
  display: none;
}

.checkboxWrp {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}

.label {
  color: var(--bg-ternary);
  font-weight: 600;
  padding-left: 0.5rem;
}

@media screen and (max-width: 912px) {
  .filterWrapper {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 1rem;
  }

  .filterButtonsDiv {
    margin-left: 0;

    .button {
      margin: 1rem 1rem 0 0;
    }
  }

  .filterShow {
    height: 8rem;
  }
}

@media screen and (max-width: 676px) {
  .filterFields {
    flex-wrap: wrap;
  }

  .filterShow {
    height: 14rem;
  }
}

@media screen and (max-width: 450px) {
  .filterShow {
    height: 19rem;
  }
}
