@mixin sizes-btn {
  &.default {
    padding: 0.5rem 1rem;
  }
  &.xs {
    padding: 0.25rem 0.5rem;
    font-size: 0.85rem;
  }
  &.sm {
    padding: 0.5rem 1rem;
  }
  &.md {
    padding: 0.75rem 2.5rem;
  }
  &.lg {
    padding: 1rem 3rem;
  }
  &.xl {
    padding: 1.25rem 4.5rem;
  }
}

@mixin sizes-dropdown {
  &.default {
    padding: 0.5rem 1rem;
    width: 100%;
  }
  &.xs {
    padding: 0.25rem 0.5rem;
    font-size: 0.85rem;
  }
  &.sm {
    padding: 0.5rem 1rem;
  }
  &.md {
    padding: 0.75rem 2.5rem;
  }
  &.lg {
    padding: 1rem 3rem;
  }
  &.xl {
    padding: 1.25rem 4.5rem;
  }
}

