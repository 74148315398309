.mx-1 {
  margin: 0 1rem;
}
.p1 {
  padding: 1rem;
}
.flex {
  display: flex;
}
.text-right {
  text-align: right;
}
.text-center {
  text-align: center;
}

.rowHover {
  .p-datatable-tbody > tr {
    &:hover {
      background-color: var(--bg-table-hover) !important;
      color: var(--text-secondary) !important;
    }
  }
}

.primeTable {
  .p-datatable-tbody > tr {
    background: var(--bg-table-row) !important;
    &.rowHover {
      &:not(.p-datatable-emptymessage):hover {
        background-color: var(--bg-table-hover) !important;
        color: white !important;
      }
    }
  }
  .p-datatable-thead > tr > th {
    background: var(--bg-table-header) !important;
  }

  .p-datatable-tbody > tr > td {
    padding: 0.5rem 0.5rem !important;
    font-size: 0.9rem !important;
  }
  .p-datatable-tbody > .p-datatable-emptymessage > td {
    padding: 0 !important;
  }
  .p-datatable-thead > tr > th {
    padding: 0.5rem 0.5rem !important;
    font-size: 0.9rem !important;
  }
}
.pzCheck .p-checkbox-box.p-highlight {
  background: var(--bg-header) !important;
  border: var(--border-color-primary) !important;
}
.p-multiselect .p-multiselect-label {
  padding: 0.5rem !important;
  min-width: 143px !important;
}

.nameTemplate {
  display: inline-flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  // max-width: 12rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;

  .id {
    font-size: 0.7rem;
    color: rgb(65, 125, 182);
    font-weight: 600;
  }
}

.section1 {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.badgeDiv {
  display: flex;
  margin-left: 2rem;
  gap: .3rem;
}

.pool {
  display: flex;
  align-items: center;
  font-size: 0.6rem;
  font-weight: 900;
  background-color: var(--bg-ternary);
  color: white;
  padding: 0.15rem;
  border-radius: 0.2rem;
}
// .p-datatable-wrapper {
//   // transform: rotateX(180deg);
//   // transform: rotateX(180deg);
//   &::-webkit-scrollbar {
//     height: 0.5rem;
//   }
// }



.name {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.gridJSON {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.val {
  color: blue;
  font-weight: 600;
}

.colGrid {
  max-width: 500px;
  overflow: hidden;
}
.noData {
  padding: 1rem;
  color: rgb(212, 212, 212);
}
@media screen and (max-width: 480px) {
  .addBtn {
    width: 3rem !important;
    height: 2rem !important;
    padding: 0.4rem !important;
  }
}

.burgerMenuIcon {
  border: 1px solid transparent;
  padding: 0.5rem;
  border-radius: 0.3rem;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    border: 1px solid white;
  }
}
