// * Flex to center the children
@mixin flex-centered($dir: row) {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: $dir;
  height: 100vh;
}

// * To justify content with space between horizontally.
@mixin flex-between($align: center) {
  display: flex;
  justify-content: space-between;
  align-items: $align;
}

// * dynamic grid mixin with default gap.
@mixin grid($template: 1fr) {
  display: grid;
  grid-template-columns: $template;
  column-gap: var(--default-gap);
  row-gap: var(--default-gap);
}
