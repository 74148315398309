.dialogWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1001;

  .backdrop {
    position: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(83, 84, 104, 0.355);
    z-index: 99;
  }
  .blurBg {
    backdrop-filter: blur(12.7px);
    -webkit-backdrop-filter: blur(12.7px);
  }
}



// Card Styles for dialog
.dialogCard {
  border: var(--border-primary-thin);

  position: relative;
  z-index: 100;
  min-width: 50%;
  .dialogHeader {
    padding: 1rem;
    font-size: 1rem;
    background: var(--bg-ternary);
    color: var(--text-secondary);
  }
  .dialogBody {
    padding: 0.8rem;
    min-height: 100px;
    background: var(--bg-primary);
    font-size: 0.8rem;
    font-weight: 100;
    // max-width: 100%;
  }




  .closeBtn {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 1.25rem;
    background: rgba(0, 128, 0, 0);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: white;
    border: none;
    height: 30px;
    width: 30px;
    // border-radius: var(--radius-default);
    border: 1px solid transparent;

    &:hover {
      background: rgba(0, 60, 255, 0.13);
      color: rgb(255, 255, 255);
      border: 1px solid rgba(240, 240, 240, 0.317);
    }
  }
}
