.headerWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--text-secondary);
  font-weight: 700;
  font-size: 1.5rem;
  /* margin-left: 1rem; */
  // padding: 1rem;
  padding-left: 20px;
  background-color: var(--bg-header);
}
.searchWrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  .iconSet {
    gap: 5px;
    background-color: var(--bg-search);
    color: var(--text-ternary);
    font-size: 0.9rem;
    display: flex;
    padding: 0.5rem 1.5rem;
    align-items: center;
  }
}

.statusWrapper {
  background-color: var(--bg-header);
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 5rem;
  margin: auto;
  border-radius: 0.2rem;
  .statusBtn {
    border: none;
    padding: 0.4rem;
    background: none;
    color: var(--text-secondary);
  }
}
.actionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  .btnWrapper {
    background-color: var(--bg-header);
    display: flex;
    align-items: center;
    margin: 0.2rem;
    max-width: 9rem;
    border-radius: 0.2rem;
    padding: 0rem 0.5rem;
  }
}

.actionBtn {
  width: 2rem;
  height: 2rem;
}

.active {
  color: #6bdb57;
}
.inactive {
  color: #db5757;
}

.GridWrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;

  .section1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    padding-bottom: 1rem;
  }
}

.buttonDiv {
  margin-top: 1rem;
  display: flex;
}

.button {
  padding: 1rem;
  // margin: 1rem 4rem;
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}

.label {
  padding-left: 1rem;
}
.editBtnWpr {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .editBtn {
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    border: none;
    font-size: 1.25rem;
    background: transparent;
    border-radius: 50%;
    justify-content: center;
    color: var(--bg-ternary);

    &:hover {
      color: white !important;
      background-color: var(--bg-ternary);
      // padding: 0.5rem;
      color: white;
    }
  }
}
.alignRight {
  justify-content: flex-end;
}



.filterIcon{
  width: 4rem !important;
  transition: 0.3s;
  width: 100%;

  &:hover{

    // border: 1px solid; 
    background-color: white;
    color:var(--bg-ternary) ;
  }
}
.iconNameWrp {
  display: flex;
  align-items: center;
  cursor: pointer;
  .nameWrp {
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }
}

.dialogButton {
  width: 6rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  // margin-top: 2rem;
}
.disabled {
  opacity: 0.1;
}

.editDialogInput {
  margin-bottom: 0.7rem;
}

@media screen and (max-width: 768px) {
  .nameWrp {
    font-size: 1rem !important;
  }
}
