.actionButtons {
  display: flex;
  button {
    &:not(:last-child) {
      margin-right: 0.5rem;
    }
  }
}
.closingBalance {
  text-align: right;
}

.alignRight {
  text-align: right;
}
