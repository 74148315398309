.searchWrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  .iconSet {
    gap: 5px;
    background-color: var(--bg-search);
    color: var(--text-ternary);
    font-size: 0.9rem;
    display: flex;
    padding: 0.5rem 1.5rem;
    align-items: center;
  }
}

.statusWrapper {
  background-color: var(--bg-header);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8rem;
  margin: 0.2rem 0.35rem;
  padding: 0 0.5rem;
  border-radius: 0.2rem;

  .statusBtn {
    padding-inline: 2rem;
    border: none;
    padding: 0.4rem;
    background: none;
    color: var(--text-secondary);
    font-size: 0.9rem;
  }
}

.actionBtn {
  width: 2rem;
  height: 2rem;
}

.GridWrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;

  .section1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    padding-bottom: 1rem;
  }
}

// .buttonDiv {
//   margin-top: 1rem;
//   display: flex;
// }

.button {
  padding: 1rem;
  // margin: 1rem 4rem;
  display: inline-block;
  width: auto;

  margin-right: 0.5rem;
}

.label {
  padding-left: 1rem;
}

.currency {
  position: relative;
}

.ACTIVE {
  color: rgb(84, 255, 78);
}
.INACTIVE {
  color: rgb(255, 78, 78);
}

.editBtnWpr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .editBtn {
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    border: none;
    font-size: 1.25rem;
    background: transparent;
    border-radius: 50%;
    justify-content: center;
    color: var(--bg-ternary);

    &:hover {
      color: white !important;
      background-color: var(--bg-ternary);
      // padding: 0.5rem;
      color: white;
    }
  }
}

.alignRight {
  justify-content: flex-end;
}

.dialogButton {
  width: 6rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  margin-top: 2rem;
}

.iconNameWrp {
  display: flex;
  align-items: center;
  cursor: pointer;
  .nameWrp {
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }
}

.filterIcon {
  // width: 4rem !important;
  transition: 0.3s;
  width: 100%;
  height: 2rem;
  &:hover {
    // border: 1px solid;
    background-color: white;
    color: var(--bg-ternary);
  }
}

@media screen and (max-width: 768px) {
  .nameWrp {
    font-size: 1rem !important;
  }
}
.generateIdAWrap {
  position: relative;
}

.generatebutton {
  border: none;
  background-color: var(--bg-ternary);
  color: white;
  padding: 0.5rem;
  border-radius: 0.2rem;
  position: absolute;
  top: 0;
  transform: translate(0%, 85%);
  right: 0.5rem;
}

.editDialogInput {
  margin-bottom: 0.7rem;
}
