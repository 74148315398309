.calenderWrapper {
  position: relative;
  // border: 1px solid green;
  // padding-top: 1rem;
  .label {
    position: absolute;
    top: 0;
    left: 0rem;
    font-size: 0.9rem;
    font-weight: 800;
    color: #686868;
  }

  .dateWrp {
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: var(--border-primary);
  }
}
