.searchWrapper {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  .iconSet {
    gap: 5px;
    background-color: var(--bg-search);
    color: var(--text-ternary);
    font-size: 0.9rem;
    display: flex;
    padding: 0.5rem 1.5rem;
    align-items: center;
  }
}

.actionBtn {
  border: none;
  border-radius: 0.2rem;
  width: auto;
  color: white;
  // padding: 0.4rem;
  // margin: 0.2rem 0.20rem;
  // background: none;
}

.statusWrapper {
  background-color: var(--bg-header);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8rem;
  margin-inline: 0.5rem;
  padding: 0 0.5rem;
  border-radius: 0.2rem;

  .statusBtn {
    // padding-inline: 2rem;
    border: none;
    padding: 0.4rem;
    background: none;
    color: var(--text-secondary);
    font-size: 0.9rem;
  }
}

.filterIcon {
  width: 4rem !important;
  transition: 0.3s;
  width: 100%;

  &:hover {
    // border: 1px solid;
    background-color: white;
    color: var(--bg-ternary);
  }
}
.statusWrapper {
  background-color: var(--bg-header);
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 8rem;
  margin: 0.2rem 0.35rem;
  border-radius: 0.2rem;

  .statusBtn {
    border: none;
    background: none;
    color: var(--text-secondary);
    font-size: 0.9rem;
    justify-content: space-evenly;
  }
  .btnWrapper {
    background-color: var(--bg-header);
    display: flex;
    align-items: center;
    max-width: 9rem;
    border-radius: 0.2rem;
  }
}

.multiselectButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.2rem;

  .multiSelectButton {
    width: 49%;
    padding: 0.6rem 0;
    font-size: 1.2rem;
    gap: 0.5rem;
    background-color: white;
    border: none;
    border-radius: 0.2rem;
    &.active{
      background-color: var(--bg-ternary);
      color: white;

    }
  }

  .buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    gap: 1rem;

    
  }
}


 


.actionBtn {
  width: 2.25rem;
  height: 2rem;
}
.GridWrapper {
  display: grid;
  grid-template-columns: 1fr;
  padding: 1rem;

  .section1 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem;
    row-gap: 1rem;
    padding-bottom: 1rem;
  }
}

.poolBadges {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.badge {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding:  0.3rem 0.5rem;
  margin:  0.5rem;
  background-color: var(--bg-ternary);
  color: rgb(235, 235, 235);
  border-radius: .2rem;
  cursor: pointer;

}






.buttonDiv {
  margin-top: 1rem;
  display: flex;
}

.button {
  padding: 1rem;
  // margin: 1rem 4rem;
  display: inline-block;
  width: auto;
  margin-right: 0.5rem;
}

.label {
  padding-left: 1rem;
}

.dialogHeader {
  color: var(--text-secondary);
  text-align: center;
}

.dialogDeleteBody {
  text-align: center;
  font-size: 1.2rem;
}

.editBtnWpr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .editBtn {
    height: 2rem;
    width: 2rem;
    display: inline-flex;
    align-items: center;
    border: none;
    font-size: 1.25rem;
    background: transparent;
    border-radius: 50%;
    justify-content: center;
    color: var(--bg-ternary);

    &:hover {
      color: white !important;
      background-color: var(--bg-ternary);
      // padding: 0.5rem;
      color: white;
    }
  }
}

.alignRight {
  justify-content: flex-end;
}

.dialogButton {
  width: 6rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  // margin-top: 2rem;
}

.iconNameWrp {
  display: flex;
  align-items: center;
  cursor: pointer;
  .nameWrp {
    font-size: 1.5rem;
    padding-left: 0.5rem;
  }
}

@media screen and (max-width: 768px) {
  .nameWrp {
    font-size: 1rem !important;
  }
}

.generateIdAWrap {
  position: relative;
}

.generatebutton {
  border: none;
  background-color: var(--bg-ternary);
  color: white;
  padding: 0.5rem;
  border-radius: 0.2rem;
  position: absolute;
  top: 0;
  transform: translate(0%, 85%);
  right: 0.5rem;
}
