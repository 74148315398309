.searchBarWrp {
  display: flex;
  align-items: center;
  // width: 100%;
  .inputWrp {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      background: #c4e6ff;
      // opacity: 0.7;
      border-radius: 0.2rem;
      border: none;
      //   color: rgb(185, 180, 180);
      padding: 0.65rem;
      padding-left: 2.5rem;
      // width: 100%;
      // height: 2.5rem;
    }
    .searchIcon {
      position: absolute;
      left: 0;
      font-size: 1.25rem;
      color: var(--text-ternary);
      top: 0;
      transform: translate(70%, 70%);
      height: 41%;
    }
  }
}

@media screen and (max-width: 480px) {

  .searchBarWrp{
    .inputWrp{
      input{
        padding: 0.5rem;
        padding-left: 2.5rem;

      }
    }
  }
}
