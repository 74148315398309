.filterWrapper {
  // background: rgb(220, 238, 250);
  // padding: 0.5rem;
}

.row {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 1rem;
  border-bottom: var(--border-primary);
}
.filterInputsWrapper {
  display: flex;
  flex-direction: column;
}
.filterButtons {
  display: inline-flex;
  gap: 0.5rem;
  //   padding-left: 0.5rem;
}

.firstPart {
  display: inline-flex;
  gap: 1rem;
  align-items: flex-end;
  // padding-left: 0.5rem;
}
.search {
  margin-left: 1rem;
}
.label {
  color: var(--text-ternary);
  margin-bottom: 0.5rem;
  font-weight: 600;
}
.exportButton {
  color: white;
}

.filterWpr {
  display: flex;
  align-items: center;
}

.filterIcon {
  // width: 4rem !important;
  transition: 0.3s;
  width: 100%;
  height: 2rem;

  &:hover {
    // border: 1px solid;
    background-color: white;
    color: var(--bg-ternary);
  }
}

@media screen and (max-width:1024px) {
  .row {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .firstPart {
    margin-bottom: 1rem;
  }
}

@media screen and (max-width: 540px) {
  .filterInputsWrapper,
  .filterButtons {
    display: block;
    gap: 0;
  }

  .firstPart {
    flex-direction: column;
    align-items: flex-start;
  }
}
