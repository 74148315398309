@import "/src/styles/mixins/severity.mixin.scss";
@import "/src/styles/mixins/size.mixin.scss";

.button {
  display: inline-flex;
  font-size: 1rem;
  justify-content: center;
  align-items: center;
  @include sizes-btn;
  &.light {
    background-color: white;
    color: var(--text-ternary);
  }
  &.warning {
    background: orange;
    color: black;
  }
  &.danger {
    background: rgb(255, 77, 0);
    color: black;
  }
}

.icon {
  margin-right: 0.5rem;
}
.flatBtn {
  border-radius: 0.2rem;
  white-space: nowrap;
  // width: 100%;
  // min-height: 2.5rem;
  // padding: 20px;

  padding: 0.5rem 1rem;
  border: none;
  color: white;
  background-color: var(--bg-ternary);
}

.spin {
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  // animation-timing-function: linear;
  font-size: 1.05rem;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.disabled {
  opacity: 0.7;
}

@media screen and (max-width: 768px) {
  .button {
    font-size: 0.8rem;
  }
  // .flatBtn{
  //   padding: 0.5rem 0.1rem;
  // }
}

@media screen and (max-width: 480px) {
  .icon {
    margin-right: 0;
  }
}
