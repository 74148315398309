.tableDiv {
  position: relative;
  min-height: 20rem;
}

.noData {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.3rem;
  min-height: 20rem;
  // z-index: -100;
  position: relative;
  &:hover{
    background-color: #F3F9FF !important;
    color: #495057;
  }
}

.loader {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: rgba(202, 239, 255, 0.554);
  min-height: 20rem;
  z-index: 100;
}
