@import "/src/styles/mixins/boxModel.mixin.scss";

.siteWrapper {
  background: var(--bg-body);
  color: var(--text-primary);
}
.layoutWrapper {
  margin: auto;
  max-width: var(--max-display);
  min-height: 100%;
  @include border-x;
}

.mainWrapper {
  display: grid;
  grid-template-columns: 305px 1fr;
}

.childrenWrapper {
  height: 100vh;
  overflow: auto;
}

@media only screen and (min-width: 1440px) {
  .shown {
    grid-template-columns: 307px 1fr;
  }
  .hidden {
    grid-template-columns: 1fr;
  }
}
@media only screen and (max-width: 1440px) {
  .mainWrapper {
    grid-template-columns: 1fr;
  }
}
.iconNameWrp {
  display: flex;
  justify-content: center;
  align-items: center;

  .nameWrp {
    margin-left: 0.5rem;
  }
}

.show {
  display: grid;
  grid-template-columns: 10px 1fr;
}
