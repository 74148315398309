.multiSelectWrapper {
  position: relative;
  // padding-top: 1rem;
}

.label {
  position: absolute;
  left: 0.5rem;
  top: -1rem;
  font-weight: 600;
  color: var(--text-ternary);
  font-size: 0.75rem;
}

.pzMultiSelect .p-multiselect-label {
  padding: 0 !important;
}

.noData {
  display: flex;
}

.selectElement {
  margin-top: 1rem;
  position: relative;
}

.input {
  min-width: 12rem;
  padding: .5rem;
  border-radius: .2rem;
  background-color: white;
  border: none;
}

.optionsDiv{
  overflow: hidden;
}

.option{
  padding: .5rem;
  min-width: 12rem;
}

.optionCard{
  display: flex;
  align-items: center;
  justify-content: center;

}

.body{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
