// * Vertical borders only;
@mixin border-y($size: 1px, $color: var(--border-color-primary), $type: solid) {
  border-top: $size $type $color;
  border-bottom: $size $type $color;
  border-left: none;
  border-right: none;
}

// * Horizontal borders only;
@mixin border-x($size: 1px, $color: var(--border-color-primary), $type: solid) {
  border-right: $size $type $color;
  border-left: $size $type $color;
  border-top: none;
  border-bottom: none;
}
