.dialogWrapper,
.confirmDialogWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  z-index: 1001;

  .backdrop {
    position: inherit;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(83, 84, 104, 0.355);
    z-index: 99;
  }
  .blurBg {
    backdrop-filter: blur(12.7px);
    -webkit-backdrop-filter: blur(12.7px);
  }
}

// Card Styles for dialog
.dialogCard {
  border: var(--border-primary-thin);

  position: relative;
  z-index: 100;
  min-width: 50%;
  .dialogHeader {
    padding: 1rem;
    font-size: 1rem;
    background: var(--bg-ternary);
    color: var(--text-secondary);
  }
  .dialogBody {
    padding: 0.5rem;
    min-height: 100px;
    background: var(--bg-primary);
    color: var(--text-ternary);
    font-size: 0.8rem;
    font-weight: 100;
  }
}

.confirmDialogCard {
  width: 25% !important;
  border: var(--border-primary-thin);
  position: relative;
  background: var(--bg-primary);
  z-index: 100;

  @media screen and (max-width: 991px) {
    width: 80% !important;
  }

  @media screen and (max-width: 480px) {
    width: 100% !important;
  }
  .confirmDialogHeader {
    background: var(--bg-ternary);
    font-weight: 600;
    font-size: 1.25rem;
    padding: 1rem;
    background: var(--bg-ternary);
    color: var(--text-secondary);
  }
  .confirmDialogBody {
    text-align: center;
    font-size: 1.1rem;
    font-weight: 400;
    color: var(--text-primary);
    padding: 0.5rem;

    // min-height: 100px;
    // font-size: 0.8rem;
  }
  .confirmDialogFooter {
    display: flex;
    border-top: var(--border-primary-thin);
    justify-content: center;
    padding: 0.5rem;
    button {
      display: inline-flex;
      width: auto;
      margin: 0 0.25rem;
    }
  }
}

.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 1.25rem;
  background: rgba(0, 128, 0, 0);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: white;
  border: none;
  height: 30px;
  width: 30px;
  // border-radius: var(--radius-default);
  border: 1px solid transparent;

  &:hover {
    background: rgba(0, 60, 255, 0.13);
    color: rgb(255, 255, 255);
    border: 1px solid rgba(240, 240, 240, 0.317);
  }
}

@media screen and (max-width: 1024px) {
  .dialogCard {
    min-width: 50%;
  }
}

@media screen and (max-width: 768px) {
  .dialogCard {
    min-width: 75%;
  }
}

@media screen and (max-width: 480px) {
  .dialogCard {
    min-width: 90%;
  }
}


.submitButtons{
  margin-top: 1rem;
  gap: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.multiselectButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.2rem;
  gap: 2rem;

  .multiSelectButton {
    width: 20rem;
    padding: 0.6rem 0;
    font-size: 1.2rem;
    gap: 0.5rem;
    background-color: white;
    border: none;
    border-radius: 0.2rem;
    &.active{
      background-color: var(--bg-ternary);
      color: white;

    }
  }

  .buttonContent {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 2rem;
    gap: 1rem;

    
  }
}

.poolsDialog{
  // color: black;
  margin-top: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: 500;
}