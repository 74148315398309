.blueWhite {
  // ! DON'T use below variables directly in the UI.
  --color-primary: #e8eff7;
  --color-primary-light: #d3d3d4;
  --color-secondary: #e8eff7;
  --color-ternary: #186aa5;
  --color-ternary-dull: #b9e2ff;
  --color-light: #ffffff;
  --color-dark: #181818;
  --color-dull: #94a2ad;
  --color-grey-dull: #b8b8b8;
  // ! DON'T use above variables directly in the UI.

  // ? Use below variable in the UI and name them specifically.
  // * Backgrounds
  --bg-body: var(--color-primary);
  --bg-table-header: #c4e9ff;
  --bg-table-row: #f3f9ff;
  --bg-header: var(--color-ternary);
  --bg-primary: var(--color-primary);
  --bg-secondary: var(--color-secondary);
  --bg-ternary: var(--color-ternary);
  --bg-search: var(--color-light);
  --bg-ternary-dull: var(--color-ternary-dull);
  --bg-active: var(--color-ternary);
  --bg-table-hover: #94d1f5;
  --bg-input: var(--color-light);

  // * Buttons
  --btn-hover-light: var(--color-ternary-dull);
  // * Texts
  --text-primary: var(--color-dark);
  --text-secondary: var(--color-light);
  --text-ternary: var(--color-ternary);
  --text-active: var(--color-ternary);
  --text-dull: var(--color-grey-dull);
  --text-primary-dull: var(--color-dull);
  --text-ternary-dull: var(--color-ternary-dull);

  // * Borders
  --border-color-primary: 1px solid var(--color-ternary);
  --border-primary: 1px solid var(--color-ternary);
  --border-primary-thick: 2px solid var(--color-ternary);
  --border-ternary-dull: 1px solid var(--color-ternary-dull);
  --border-grey-dull: 1px solid var(--color-grey-dull);
}
